import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';

interface QRScannerProps {
    onResult: (decodedText: string) => void;
    scanOnload?: boolean,
}

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode: Html5Qrcode | null = null;

const QRScanner: React.FC<QRScannerProps> = ({ onResult, scanOnload= false }) => {
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const qrCodeSuccessCallback = (decodedText: string) => {
        onResult(decodedText);
        stopScanning();
    };
    const qrCodeErrorCallback = (err: any) => {
        // console.log(err)
    };
    useEffect(() => {
        if (!html5QrCode) {
            html5QrCode = new Html5Qrcode('reader');

            if (scanOnload) {
                startScanning();
            }
        }

        return () => {
            stopScanning();
        };
    }, []);

    const startScanning = () => {
        if (html5QrCode && !isScanning) {
            html5QrCode.start(
                { facingMode: 'environment' },
                qrConfig,
                qrCodeSuccessCallback,
                qrCodeErrorCallback
            )
                .then(() => {
                    setIsScanning(true);  // Update state only after successful start
                })
                .catch((err: any) => {
                    console.error(`Unable to start scanning: ${err}`);
                });
        }
    };

    const stopScanning = () => {
        if (html5QrCode && (html5QrCode.isScanning || isScanning)) {
            html5QrCode.stop()
                .then(() => {
                    html5QrCode?.clear();
                    setIsScanning(false);
                })
                .catch((err: any) => {
                    console.warn(`Cannot stop, scanner is not running: ${err}`);
                });
        }
    };

    return (
        <>
            <div id="reader" style={{ width: '100%' }} />
            {isScanning ? (
                <button className="btn btn-outline-danger mt-3" onClick={stopScanning}>
                    Stop Scanning
                </button>
            ) : (
                <button className="btn btn-outline-primary mt-3" onClick={startScanning}>
                    Start Scanning
                </button>
            )}
        </>
    );
};

export default QRScanner;
