import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import React from "react";
import * as HeroIcons from '@heroicons/react/24/solid';
import {connect} from "react-redux";
import {BeakerIcon} from "@heroicons/react/24/outline";

interface ButtonProps {
    submitting: boolean;
    target: string,
    label?: string;
    className?: string;
    id: string;
    clickFunction?: (event: React.MouseEvent) => void;
    Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    variant?: string;
    type?: "button" | "submit" | "reset";
    style?: {}
}

const UIButton: React.FC<ButtonProps> = ({
                                             submitting,
                                             target,
                                             id,
                                             label,
                                             className,
                                             clickFunction = (e) => {
                                                 console.log('nothing here')
                                                 return;
                                             },
                                             Icon,
                                             variant = 'outline-primary',
                                             type = "button",
                                             style = { marginRight: '10px' }
                                         }) => {
    const t = (str: string) => str;
    submitting = submitting && (target === id)
    return (
        <>
            <Button
                variant={variant}
                className={className}
                disabled={submitting}
                onClick={type === "button" ? clickFunction : undefined} // Add clickFunction only if type is "button"
                style={style}
                type={type}
            >
                {submitting && (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )}
                {Icon && !submitting && (
                    <Icon style={{ width: '25px', height: '25px' }} />
                )}
                {label && (
                    <span style={{paddingLeft: '3px'}}>{submitting ? "Loading..." : t(label)}</span>
                )}
            </Button>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    submitting: state.loaders.submitButton,
    target: state.loaders.target,
});

export default connect(mapStateToProps)(UIButton);
