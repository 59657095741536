import * as actionTypes from '../actionsTypes';
import {IAssetState} from "../models/IAsset";

const initialAssetState: IAssetState = {
    asset: undefined,
    requested_activity: undefined,
    assets: [],
    activityTypes: [],
};

export const asset = (state: IAssetState = initialAssetState, action: any): IAssetState => {
    switch (action.type) {
        case actionTypes.PROBE_ASSET:
            return {
                ...state,
                asset: action.payload
            };

        case actionTypes.REQUEST_ACTIVITY_CREATION:
            return {
                ...state,
                requested_activity: action.payload
            };

        case actionTypes.GET_ACTIVITY_TYPES:
            return {
                ...state,
                activityTypes: action.payload
            };

        default: {
            return state;
        }
    }
};
