import ApiClient, { parseRequestData } from "../utilities/http";
import {Endpoints} from "../utilities/endpoints";
import {endSubmitting, isSubmitting} from "./loadActions";
import {get} from "lodash";
import {IActivity, IActivityType, IAsset} from "../models/IAsset";
import * as actionTypes from "../actionsTypes";
import {toastError, toastSuccess} from "./formInputAction";

export const assetProbe = (code: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        ApiClient.get(Endpoints.ASSET + '?code=' + encodeURIComponent(code))
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));
                if(get(response, 'statusText') == 'OK'){
                    const assetItem: IAsset = get(response, 'data.asset_item');
                    if(assetItem){
                        dispatch({
                            type: actionTypes.PROBE_ASSET,
                            payload: {...assetItem}
                        })
                        return;
                    }
                }
                toastError({}, 'Error Fetching Asset')
            })
            .catch(function (error){
                toastError(error, 'Error Fetching Asset')
            })
    }
}

export const getActivityTypes = () => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        ApiClient.get(Endpoints.ACTIVITY_TYPES)
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));
                if(get(response, 'statusText') == 'OK'){
                    const types: IActivityType[] = get(response, 'data.activity_types');
                    if(types){
                        dispatch({
                            type: actionTypes.GET_ACTIVITY_TYPES,
                            payload: [...types]
                        })
                        return;
                    }
                }
                toastError({}, 'Error Fetching Activity Types')
            })
            .catch(function (error){
                toastError(error, 'Error Fetching Activity Types')
            })
    }
}

export const requestActivity = (data: any, targetBtn: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
            target: targetBtn
        }));

        ApiClient.post(Endpoints.REQUEST_ACTIVITY + '/' + data['asset_id'] + '/' + data['activity_type_id'],
            parseRequestData(data, 'multipart'), {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));

                if(get(response, 'statusText') == 'OK'){
                    const activity: IActivity = get(response, 'data.activity');
                    if(activity){
                        dispatch({
                            type: actionTypes.REQUEST_ACTIVITY_CREATION,
                            payload: {...activity}
                        })
                        dispatch(toastSuccess('Activity Request', 'Activity Request is successfully placed'))
                        setTimeout(() => window.location.replace('/dashboard'), 2000)
                        return;
                    }
                }
                dispatch(toastError({}, 'Error Requesting Activity'))
                dispatch(endSubmitting({
                    submitButton: false,
                }));
            })
            .catch(function(error){
                dispatch(toastError(error, 'Error Requesting Activity'))
                dispatch(endSubmitting({
                    submitButton: false,
                }));
            })
    }
}
