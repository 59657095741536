import { Button, Table } from "react-bootstrap";
import { ICard } from "../models/ICard";
import { MouseEventHandler } from "react";

function CountedCardsTable(
    { cards, clickHandler }:
        { cards: ICard[], clickHandler: MouseEventHandler<HTMLTableRowElement> }) {
    if (cards.length === 0) {
        return null;
    }
    return (
        <Table responsive >
            <thead>
                <tr>
                    <td>Artikelcode</td>
                    <td>Geteld</td>
                </tr>
            </thead>
            <tbody>
                {cards.map((card) => (
                    <tr key={card.id} id={card.id} onClick={clickHandler}>
                        <td>{card.product_code}</td>
                        <td>{card.countedInventory}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default CountedCardsTable;