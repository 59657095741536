import axios from 'axios';

import config from "./Config";
import {Endpoints} from "./endpoints";
import cookie from "./cookie";
import {Constants} from "./constants";
// import {date} from "yup";
import {QueryParams} from "../models/types";

export class Utility {
    static debounced: any = 0;
    static flagMap: Map<string, string> = new Map<string, string>([
        ['default', 'flag-icon-us'],
        ['en', 'flag-icon-us'],
        ['fr', 'flag-icon-fr']
    ]);

    static debounce (func: () => void, time: number) {
        clearTimeout(this.debounced);
        this.debounced = setTimeout(func, time);
    }

    static getAppLink(name: string)
    {
        return config.url + "/apps/" + name;
    }

    static getFlagIcon(language: string)
    {
        if(this.flagMap.has(language)) return this.flagMap.get(language);
        return this.flagMap.get('default');
    }

    static generateRandomString(len: number) {
        let text = '';
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < len; i++) {
            text += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return text.toString();
    }

    static updateQueryStringParameter(uri: string, key: string, value: any) {
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        const separator = uri.toString().indexOf('?') !== -1 ? '&' : '?';
        let requestUrl = '';
        if (separator === '&' && uri.toString().match(re)) {
            requestUrl = uri.toString().replace(re, '$1' + key + '=' + value.toString() + '$2');
        } else {
            requestUrl = uri + separator + key + '=' + value.toString();
        }
        return requestUrl.toString();
    }

    static objectToQueryString(url: string, params: QueryParams): string {
        const queryParams: string[] = [];

        const appendQueryParams = (obj: Record<string, any>, prefix?: string) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    const paramKey = prefix ? `${prefix}[${key}]` : key;

                    if (Array.isArray(value)) {
                        value.forEach((item, index) => {
                            if (typeof item === 'object') {
                                appendQueryParams(item, `${paramKey}[${index}]`);
                            } else {
                                queryParams.push(`${paramKey}[${index}]=${encodeURIComponent(item)}`);
                            }
                        });
                    } else if (typeof value === 'object') {
                        appendQueryParams(value, paramKey);
                    } else {
                        queryParams.push(`${paramKey}=${encodeURIComponent(value)}`);
                    }
                }
            }
        };

        appendQueryParams(params);

        const queryString = queryParams.join('&');

        if (url.includes('?')) {
            return `${url}&${queryString}`;
        } else {
            return `${url}?${queryString}`;
        }
    }

    static async refreshToken()
    {
        const http = axios.create({
            baseURL: config.api,
        });
        const refreshToken = cookie.get(Constants.REFRESH_KEY.toLocaleString());
        if(refreshToken){
            const {data} = await http.post(Endpoints.REFRESH_TOKEN, {token: refreshToken}).catch(
                err => { return {data: ''}}
            );
            if(data){
                cookie.put(Constants.ACCESS_KEY, data['access_token'], 'Lax', true);
                cookie.put(Constants.REFRESH_KEY, data['refresh_token'], 'Lax', true);
                return data['access_token'];
            }
        }
        return false;
    }

    static redirectToLogin()
    {
        if(window.location.pathname === '/' + Constants.LOGIN_URL) return;
        window.location.replace(config.url + Constants.LOGIN_URL);
        return;
    }

    static parseTenantPath(url: string){
        const tenant = cookie.get('tenant');
        if(!tenant) return url;
        return url.replace('{tenant}', tenant)
    }

    static initiateType (model: string, value: any = ''): any {
        const ret:any = {
            item: {
                name: value,
                taxId: value,
                description: value,
                salesPrice: value,
                purchasePrice: value,
                sku: value,
                categoryIds: [],
                openingStock: value,
                enabled: false,
                tracking: false,
                images: []
            }
        }

        return ret[model]
    }

    static getTimeFromNow(value: number, denominator: string = 'sec'){
        let multiply = 1000;
        if(denominator.indexOf('sec') !== -1) multiply *= 1;
        if(denominator.indexOf('min') !== -1) multiply *= 60;
        if(denominator.indexOf('hr') !== -1 || denominator.indexOf('hour') !== -1) multiply *= 3600;
        return new Date((new Date()).getTime() + (value * multiply)).getTime();
    }

    static getEndOfDay(){
        const time = new Date();
        time.setHours(23,59,59,999)
        return time.getTime();
    }

    static secondsToHMS(d: number) {
        const h = Math.floor(d / 3600);
        const m = Math.floor(d % 3600 / 60);
        const s = Math.floor(d % 3600 % 60);

        const hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
        const mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
        const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    static formatAsDate(str: string|undefined){
        if(!str) return undefined
        return new Date(str)
    }

    static formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    static replaceItem(array: any, item: any, field: string = 'id'){
        const newArray = [...array]
        for (let i = 0; i < newArray.length; i++) {
            if (newArray[i][field] === item[field]) {
                newArray[i] = item
            }
        }
        return newArray;
    }

    static removeItem(array: any, item: any, field: string = 'id'){
        const rm = Array.isArray(item) ? item : (item[field] || item).split(',');
        return [...array].filter(arrItem => !rm.includes(arrItem[field] + ""));
    }

    static addItem(array: any, item: any){
        return [...array, item];
    }

    static formatCurrency = (number: number, currency="$") => {
        return currency + Number(number).toFixed(2)
    }

    static getQueryParam(location: any){
        return new URLSearchParams(location.search.substring(1));
    }

    static getUrlSearchParam = (key: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }

    static formatFileSize = (size: number) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    }

    static formatRelativeTime = (dateTime: Date): string => {
        const now = new Date();
        const diffInMilliseconds = now.getTime() - dateTime.getTime();

        // Calculate the differences in time units
        const seconds = Math.floor(diffInMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        if (years > 0) {
            return `${years} year${years > 1 ? 's' : ''} ago`;
        } else if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }
    }

    static discernFileType = (extension: string): string => {
        const fileTypeMap: { [key: string]: string[] } = {
            "Document File": ["doc", "docx", "txt", "xls", "xlsx"],
            "Image File": ["png", "gif", "jpg", "jpeg", "ico", "webp"],
            "Video File": ["avi", "mp4"],
            "Music File": ["mp3", "midi"],
            "PDF File": ["pdf"],
            "Sketch File": ["sketch"],
            "Archives": ["zip", "rar"],
        };

        for (const key of Object.keys(fileTypeMap)) {
            const extensions = fileTypeMap[key];
            if (extensions.includes(extension)) return key;
        }

        return "Unknown File";
    };
}

