export enum Endpoints {
    ACTIVITY = 'activity',
    ACTIVITY_TYPES = 'activity-types',
    ASSET = 'asset',
    LOCATION = 'location',
    LOG_IN = 'login',
    LOG_OUT = 'logout',
    REFRESH_TOKEN = 'refresh-token',
    REQUEST_ACTIVITY = 'request-activity',
    USER_STATUS_HEART_BEAT = 'heart-beat',
    CARD = 'card',
    CARD_UPDATE = 'card-update',
    INVENTORY_UPDATE = 'inventory-update',
}
