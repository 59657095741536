export const local = {
    envName: 'local',
    appName: 'Werk App',
    debug: true,
    bypassAuth: true,
    clientIdentifier: "z00WpXQTcyMJGAKpMpZs9MdrhpFOQY0K9km4fv3h7mDnbSDa4VuhWYUDuUigA7Zy",
    // api                 : 'https://{tenant}.sense2.test/api/',
    api: 'http://{tenant}.localhost/api/',
    // url: 'https://m.sense2.test:3000/',
    url: 'http://localhost:3000/',
};
