import {FormState} from "../models/types";
import * as actionTypes from "../actionsTypes";

export const formInputInit: FormState = {
    submitting: false,
    contextComponent: 'app',
    deleteConfirmed: false,
    errors: [],
    info: []
};

export const formInputs = (state = formInputInit, action: any) => {
    switch (action.type) {
        case actionTypes.REQUEST_STARTS:
            return {
                ...state,
                submitting: true
            };
        case actionTypes.REQUEST_ENDS:
            return {
                ...state,
                submitting: false
            };
        case actionTypes.ADD_ERROR:
            return {
                ...state,
                errors: {...action.payload.errors}
            }
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                errors: {...action.payload.errors}
            }
        case actionTypes.ADD_INFO:
            return {
                ...state,
                errors: {...action.payload.infos}
            }
        case actionTypes.CLEAR_INFO:
            return {
                ...state,
                errors: {...action.payload.infos}
            }
        case actionTypes.CONFIRM_DELETE:
            return {
                ...state,
                deleteConfirmed: action.payload
            }
        default: {
            return state;
        }

    }
};

