import config from "./Config";

export interface ICookie {
    get: (key: string) => any;
    put: (key: string, value: any, sameSite: string, secure: boolean, path?: any, expireIn?: any) => void;
    getObject: (key: string) => void;
    getAll: () => any;
    removeAll: () => void;
    remove: (key: string, options?: any) => void;
    putObject: (key: string, value: any, options?: any) => void;
}

class Cookie implements ICookie {
    public cookieCache: Map<string, any>;
    constructor() {
        this.cookieCache = new Map();
        this.parseCookies(document.cookie || '');
    }
    public put(key: string, value: any, sameSite: string = "None", secure: boolean = false, path?: any, expireIn = 15 * 24 * 60 * 60 * 1000) {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireIn);
        const expires = `expires=${d.toUTCString()}`;
        path = (path && path.toString().indexOf('/') === 0) ? path : '/';
        path = `path=${path}`;
        secure = config.envName == 'production' && secure;
        sameSite = secure ? ` SameSite=${sameSite};` : ``
        document.cookie = `${key}=${value}; ${path}; ${expires}; ${sameSite}` + (secure ? ' Secure;' : '');
        this.cookieCache.set(key, value);
    }

    public parseCookies(cookies: string) {
        if (!!cookies === false) { return; }
        const cookiesArr = cookies.split(';');
        for (const ce of cookiesArr) {
            const cookieArr = ce.split('=');
            if(cookieArr[1] == undefined) continue;
            this.cookieCache.set(cookieArr[0].trim(), cookieArr[1].trim());
        }
    }

    public get(key: string): any {
        this.parseCookies(document.cookie || '');

        return this.cookieCache.get(key);
    }

    public getObject(key: string): any {
        return undefined;
    }

    public getAll(): any {
        return this.cookieCache;
    }

    public putObject(key: string, value: any, options?: any): void {
        this.put(key, value);
    }

    // public remove(key: string, options?: any): void {
    //     this.put(key, null, 'None', false, '/', -60);
    //     this.cookieCache.delete(key);
    // }
    public remove(key: string, path: string = '/', sameSite: string = 'None', secure: boolean = false): void {
        const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC'; // Set the cookie to expire in the past
        path = (path && path.toString().indexOf('/') === 0) ? path : '/';
        path = `path=${path}`;
        secure = config.envName === 'production' && secure;
        sameSite = secure ? ` SameSite=${sameSite};` : ``

        // Clear the cookie by setting an expiration in the past
        document.cookie = `${key}=; ${path}; ${expires}; ${sameSite}` + (secure ? ' Secure;' : '');

        // Remove from the cache
        this.cookieCache.delete(key);
    }

    public removeAll(): void {
        this.cookieCache.clear();
    }
}
const cookie = new Cookie();
export default cookie;
