import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useField } from 'formik';
import Webcam from 'react-webcam';
import { Camera, Aperture, X } from 'lucide-react';
import UIButton from "./UIButton"; // Import icons

interface FileUploadFieldProps {
    label: string;
    name: string;
    id?: string;
    accept?: string;
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({ label, accept = "image/*", ...props }) => {
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    // State to hold the file preview URL and camera mode
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isWebcamOpen, setIsWebcamOpen] = useState(false);

    const webcamRef = React.useRef<Webcam>(null);

    // Capture a picture from the webcam
    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                // Convert base64 to a File object
                const byteString = atob(imageSrc.split(",")[1]);
                // @ts-ignore
                const mimeType = imageSrc.split(",")[0].match(/:(.*?);/)[1];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);

                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                const file = new File([ab], "captured_image.jpg", { type: mimeType });
                setValue(file); // Set Formik value with the File object
                setPreviewUrl(imageSrc); // Set preview URL for display
                setIsWebcamOpen(false); // Close the webcam after capturing
            }
        }
    }, [setValue]);


    // Handle file upload changes
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
        setValue(file);

        if (file && file.type.startsWith("image/")) {
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        } else {
            setPreviewUrl(null);
        }
    };

    // Cleanup URL to prevent memory leaks
    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <Form.Group controlId={props.id || props.name}>
            <Form.Label>{label}</Form.Label>

            {/* File Upload Control */}
            <div className='d-flex'>
                <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    name={props.name}
                    accept={accept}
                    isInvalid={!!meta.touched && !!meta.error}
                />
                <UIButton variant={isWebcamOpen ? 'danger' : 'primary'} clickFunction={() => setIsWebcamOpen(!isWebcamOpen)} Icon={isWebcamOpen ? X : Camera} id='take-picture' style={{ marginLeft: '10px' }} />
            </div>


            {/* Error message for Formik */}
            {meta.touched && meta.error ? (
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            ) : null}

            {/* Display thumbnail preview if there is a preview URL */}
            {previewUrl && !isWebcamOpen && (
                <div className="mt-2 d-flex justify-content-center">
                    <img src={previewUrl} alt="Preview" style={{ maxWidth: "200px", maxHeight: "200px" }} />
                </div>
            )}

            {/* Webcam View */}
            {isWebcamOpen && (
                <div className="mt-3 position-relative d-flex justify-content-center">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={200}
                        height={150}
                        videoConstraints={{
                            facingMode: "environment"
                        }}
                    />
                    <UIButton id='capture-btn' Icon={Camera} clickFunction={capture} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}/>
                </div>
            )}
        </Form.Group>
    );
};

export default FileUploadField;
