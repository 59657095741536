import { ILoading } from "../models/types"
import  * as actionTypes from "../actionsTypes"

export const isSubmitting = (authData: ILoading = {}) => ({
    type    : actionTypes.SUBMIT_STARTS,
    payload : {...authData}
})

export const endSubmitting = (authData: ILoading = {}) => ({
    type    : actionTypes.SUBMIT_ENDS,
    payload : {...authData}
})

export const isParallelLoading = () => ({
    type    : actionTypes.PARALLEL_LOAD_STARTS,
    payload : {...{parallelLoad: true}}
})

export const endParallelLoading = () => ({
    type    : actionTypes.PARALLEL_LOAD_ENDS,
    payload : {...{parallelLoad: false}}
})

export const isCheckingLoginStatus = (authData: ILoading) => ({
    type    : actionTypes.APP_CHECK_LOGIN_START,
    payload : {...authData}
})

export const endCheckingLoginStatus = (authData: ILoading) => ({
    type    : actionTypes.APP_CHECK_LOGIN_END,
    payload : {...authData}
})

