import { IAuthState } from '../models/types';
import { Endpoints } from '../utilities/endpoints';
import * as actionTypes from '../actionsTypes';
import ApiClient, { parseRequestData } from '../utilities/http';
import cookie from '../utilities/cookie';

const getInitialUserState = async () => {
    try {
        const checkLogin = await ApiClient.put(Endpoints.USER_STATUS_HEART_BEAT as string,
            parseRequestData({status: 'online'}, 'PUT'));

        return {
            currentUser     : JSON.parse(cookie.get('current_user')),
            isAuthenticated : true
        }
    } catch(err) {
        return {
            currentUser     : undefined,
            isAuthenticated : false
        }
      }
}

export const initialAuthState: IAuthState = {
    currentUser     : undefined,
    isAuthenticated : false
}

export const auth = (state = initialAuthState, action: any) => {
    switch (action.type) {
        case actionTypes.LOG_IN:
            return {
                currentUser     : action.payload.currentUser,
                isAuthenticated : action.payload.isAuthenticated
            };
        default: {
            return state;
        }
    }
  };

