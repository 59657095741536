export enum Constants {
    USER_ID = 'userId',
    SESSION_ID = 'sessionId',
    ACCESS_KEY = 'YTU3I9403RRE610',
    CSRF_KEY = 'csrf_request_token',
    REFRESH_KEY = 'YTU3I9403RRE630',
    USER_STATUS_POLLING = 10000, // In Milliseconds
    NOTIFICATION_POLLING = 5000, // In Milliseconds
    LOGIN_URL = 'login',
    BASE_PATH = 'base',
    SYSTEM_BASE_PATH = 'system-base'
}
