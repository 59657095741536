import React, {useState} from 'react';
import '../assets/styles/App.css';
import AuthService from "../services/AuthService";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Navigate, useNavigate} from "react-router-dom";
import BottomSheet from "../components/bottom-sheet";
import Inquiry from "./Inquiry";
import UIButton from "../components/utilities/UIButton";
import {
    AlertTriangle,
    Briefcase,
    Calculator,
    CheckSquare,
    CircleHelp, ClipboardList,
    FilePenLine, MapPinHouse, PackagePlus,
    ScanEye, ThermometerSun,
    UserPlus
} from "lucide-react";
import ScanInventory from './ScanInventory';

interface SquareButtonProps {
    icon: React.JSX.Element;
    label: string;
    onClick: () => void;
}

function Dashboard() {
  const user = AuthService.getUser();
  const navigate = useNavigate();
    const [isSheetOpen, setIsSheetOpen] = useState(false);

    const openTasks = 432;
    const totalAssets = 337;

    const openSheet = () => {
        navigate('/inquiry')
    };

    const scanInventory = () => {
        navigate('/scan_inventory')
    };


    const closeSheet = () => {
        setIsSheetOpen(false);
    };

    const genericClickHandler = () => console.log('Execute button handler')
    const actionButtons = [
        { name: 'Inquiry', icon: ScanEye, clickHandler:  openSheet, enabled: true},
        // { name: 'Request', icon: CircleHelp, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Modify', icon: FilePenLine, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Activities', icon: ClipboardList, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Register', icon: PackagePlus, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Sensors', icon: ThermometerSun, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Locate', icon: MapPinHouse, clickHandler: genericClickHandler, enabled: false},
        // { name: 'Count', icon: Calculator, clickHandler: genericClickHandler, enabled: false},

        { name: 'Inventory', icon: Calculator, clickHandler: scanInventory, enabled: true},
        //TODO Erik: add inventory button... or is that count?
    ];

      return (
        <Container className="flex-grow-1 py-4">
            {/* Summary Section */}
            <Row className="g-3 mb-4 d-none">
                <Col xs={6}>
                    <div className="bg-primary text-white p-3 rounded shadow-sm">
                        <p className="mb-1 small">Open Tasks</p>
                        <h2>{openTasks}</h2>
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="bg-success text-white p-3 rounded shadow-sm">
                        <p className="mb-1 small">Total Assets</p>
                        <h2>{totalAssets}</h2>
                    </div>
                </Col>
            </Row>

            {/* Action Buttons */}
            <div className="bg-white rounded shadow-md p-4 mb-4">
                <h3 className="h5 mb-3">Actions</h3>
                <Row className="g-3">
                    {actionButtons.map((button) => (
                        <Col xs={6} key={button.name}>
                            <Button variant="light" disabled={!button.enabled} onClick={button.clickHandler} className="d-flex flex-column align-items-center justify-content-center py-3 shadow-sm w-100">
                                <button.icon className="mb-2 text-primary" size={24} />
                                <span className="small">{button.name}</span>
                            </Button>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
  );
}

export default Dashboard;
//
