import {staging} from './env/staging';
import {local} from './env/local';
import {production} from './env/production';
import {Utility} from "./utility";

const environment = process.env.NODE_ENV;
let config = local;
if (environment === 'development') { config = local; }
if (environment === 'production') {
    /** @TODO:Live set this environment variable */
    if(process.env.APP_ENV && process.env.APP_ENV === 'live')
        config = production;
    config = staging
}

config.api = Utility.parseTenantPath(config.api)

export default config;
