import React from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import '../assets/styles/App.css'

const TenantNotFound :  React.FC = () => {
    return (
        <Container className="mt-5 Login">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h2 className="text-center">Welcome! Please check the URL again</h2>
                </Col>
            </Row>
        </Container>
    );
}

export default TenantNotFound;
