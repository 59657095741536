import {IToastsState, IToastState} from "../models/types";
import * as actionTypes from '../actionsTypes';

const initialToastState: IToastsState = {
    toasts: []
};

export const toast = (state: IToastsState = initialToastState, action: any): IToastsState => {
    switch (action.type) {
        case actionTypes.ADD_TOAST:
            return {
                ...state,
                toasts: [...state.toasts, action.payload]
            };

        case actionTypes.REMOVE_TOAST:
            return {
                ...state,
                toasts: state.toasts.filter(toast => toast.id !== action.payload.id)
            }

        case actionTypes.SET_TOAST:
            return {
                toasts: [...action.payload]
            }

        default: {
            return state;
        }
    }
};
