import * as Yup from 'yup';

const LoginValidator = Yup.object().shape({
    password: Yup.string().min(5).max(20).required(),
    username: Yup.string().required(),
    platform: Yup.string().matches(
        /^[a-zA-Z0-9]([a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?$/,
        'Invalid platform name. Must start and end with a letter or number, ' +
        'and contain only letters, numbers, and hyphens.'
    )
        .min(1, 'Platform must be at least 1 character long.')
        .max(63, 'Platform cannot be longer than 63 characters.')
        .required('Platform is required.'),
});

export default LoginValidator;
