import cookie from "../utilities/cookie";
import {Utility} from "../utilities/utility";

class AuthService{
    static getUserId(){
        const user = AuthService.getUser();
        return user?.id;
    }
    static getUser(){
        let user = null;
        try {
            user = JSON.parse(cookie.get('current_user'));
        } catch (e) {}

        if(!user) {
            Utility.redirectToLogin()
        }
        return user;
    }
}

export default AuthService;
