import { ICard } from "../models/ICard"
import { ILocation } from "../models/ILocation"
import { Endpoints } from "../utilities/endpoints";
import { isSubmitting } from "./loadActions";
import ApiClient from "../utilities/http";



export const submitInventoryCounts = ({ location, countedCards }: { location: ILocation, countedCards: ICard[] }) => {
    console.error('unused!');
    debugger;
    // return (dispatch: any) => {
    //     dispatch(isSubmitting({
    //         submitButton: true,
    //     }));
    // let cards = countedCards.map(card => { { id: card.id; num: card.countedInventory } });
    let cardData = countedCards.map(({ id, countedInventory }) => ({ id: id, counted: countedInventory }));



    ApiClient.post(Endpoints.INVENTORY_UPDATE, { location: location.id, cards: cardData })
        .then(function (response) {
            debugger;
            //TODO if success then report back and set stage to START
        })
        .catch((err) => {
            debugger;
        });
}
// }

//TODO add token cookie
export async function submitInventoryCounts2(location: ILocation, cards: ICard[]) {
    let cardData = cards.map(({ id, countedInventory }) => ({ id: id, counted: countedInventory }));
    return new Promise(async (resolve, reject) => {
        if (cards.length === 0) {
            reject('no cards, not submitting');
        }
        ApiClient.post(Endpoints.INVENTORY_UPDATE,
            { location: location.id, cards: cardData })
            .then(res => { resolve(res) })
            .catch(err => { reject(err) });
    });
}