import {Loading} from "../models/types";
import * as actionTypes from "../actionsTypes";

export const loaderInit: Loading = {
    submitButton: false,
    appComponent: undefined,
    target: undefined,
};

export const loaders = (state = loaderInit, action: any) => {
    switch (action.type) {
        case actionTypes.SUBMIT_STARTS:
            return {
                ...state,
                submitButton: true,
                target: action.payload.target,
            };
        case actionTypes.SUBMIT_ENDS:
            return {
                ...state,
                submitButton: false,
                target: action.payload.target,
            };
        case actionTypes.PARALLEL_LOAD_STARTS:
            return {
                ...state,
                parallelLoad: true
            };
        case actionTypes.PARALLEL_LOAD_ENDS:
            return {
                ...state,
                parallelLoad: false
            };
        case actionTypes.APP_CHECK_LOGIN_START:
            return {
                ...state,
                appComponent: true
            }
        case actionTypes.APP_CHECK_LOGIN_END:
            return {
                ...state,
                appComponent: false
            }
        default: {
            return state;
        }
    }
};

